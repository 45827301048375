import BaseService from './BaseService'
const url = '/employees'

export const getEmployees = (code) => {
    return BaseService.get(`${url}?role.code=${code}`)
}

export const getAllEmployees = () => {
    return BaseService.get(`${url}`)
}

export const getEmployeeByName = (name) => {
    const url = `/employees?itemsPerPage=100000&role.code=TELLER&fullname=${name}`

    return BaseService.get(url)
}

export const getEmployeeByPartition = (partition) => {
    const url = `/employees?itemsPerPage=100000&role.code=TELLER&partition=${partition}`

    return BaseService.get(url)
}

export const getEmployeeByNameAndPartition = (name, partition) => {
    const url = `/employees?role.code=TELLER&partition=${partition}&fullname=${name}`

    return BaseService.get(url)
}

export const getEmployee = (id) => {
    const url = '/users/' + id

    return BaseService.get(url)
}
