import BaseService from './BaseService'

const url = '/saving_card_configurations'
const url_saving_card = '/saving_cards'
const url_saving_card_documents = '/saving_card_owner_ids'

export const getConfigs = () => {
    return BaseService.get(url)
}

export const getConfigById = (id) => {
    return BaseService.get(`${url}/${id}`)
}

export const createConfig = (data) => {
    return BaseService.post(`${url}`, data)
}

export const getCard = (id) => {
    return BaseService.get(`${url_saving_card}/${id}`)
}

export const updateConfig = (data, id) => {
    return BaseService.put(`${url}/${id}`, data)
}

export const createCard = (data) => {
    return BaseService.post(`${url_saving_card}`, data)
}

export const getCardById = (cardId) => {
    const url = `/saving_cards/${cardId}`

    return BaseService.get(url)
}

export const getSearchCards = (url_) => {
    const url = `/saving_cards?itemsPerPage=100000${url_ !== '' ? '&' : ''
        }${url_}&order[openedAt]=desc`

    return BaseService.get(url)
}

export const getSearchCardsDeposit = (url_) => {
    const url = `/saving_card_collections?itemsPerPage=100000${url_ !== '' ? '&' : ''
        }${url_}&order[collectedAt]=desc`

    return BaseService.get(url)
}

export const cardValidation = (id) => {
    const url = `/saving_cards/validations`
    return BaseService.post(url, {
        card: `/api/saving_cards/${id}`,
    })
}

export const makeCollection = (data) => {
    const url = `/saving_cards/collections`
    return BaseService.post(url, data)
}

export const makeWithdraw = (data) => {
    const url = `/saving_cards/withdraw`
    return BaseService.post(url, data)
}

export const cardCancellation = (id) => {
    const url = `/saving_cards/cancellations`
    return BaseService.post(url, {
        card: `/api/saving_cards/${id}`,
    })
}

export const getCards = () => {
    const url = '/saving_cards?itemsPerPage=500000'

    return BaseService.get(url)
}

export const getCardOwnerDocuments = (id) => {
    const url = `/saving_card_owner_ids?savingCard=${id}`

    return BaseService.get(url)
}

export const createCardOwnerDocument = async (data) => {
    return await BaseService.post(url_saving_card_documents, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}
