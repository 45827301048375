import * as actions from '../actions/infos'
import { getEmployee } from '../../services/EmployeeService'
import {
    TYPE_ACCOUNT,
    TYPE_ACCOUNT_BY_NUMBER,
    TYPE_CUSTOMER,
    TYPE_EMPLOYEE,
    TYPE_PARTITION,
    TYPE_SAVING_CARD,
} from '../../constants/type.constant'
import { getPartition } from '../../services/PartitionService'
import { getCustomerById } from '../../services/CustomerService'
import {
    getAccountByAccountNumber,
    getAccountId,
} from 'services/AccountService'
import { getCardById } from 'services/SavedCard'

const infos =
    ({ dispatch }) =>
    (next) =>
    async (action) => {
        if (action.type !== actions.infosCallBegan.type) return next(action)

        const { type, code, onStart, onSuccess, onError } = action.payload

        if (onStart) dispatch({ type: onStart, payload: { type, code } })

        next(action)

        if (type === TYPE_EMPLOYEE) {
            await getEmployee(code)
                .then((response) => {
                    dispatch(actions.infosCallSuccess(response.data))
                    if (onSuccess)
                        dispatch({
                            type: onSuccess,
                            payload: { type, code, data: response.data },
                        })
                })
                .catch((error) => {
                    dispatch(actions.infosCallFailed(error.message))
                    if (onError)
                        dispatch({ type: onError, payload: { type, code } })
                })
        }
        if (type === TYPE_PARTITION) {
            await getPartition(code)
                .then((response) => {
                    dispatch(actions.infosCallSuccess(response.data))

                    if (onSuccess)
                        dispatch({
                            type: onSuccess,
                            payload: { type, code, data: response.data },
                        })
                })
                .catch((error) => {
                    dispatch(actions.infosCallFailed(error.message))
                    if (onError)
                        dispatch({ type: onError, payload: { type, code } })
                })
        }
        if (type === TYPE_CUSTOMER) {
            await getCustomerById(code)
                .then((response) => {
                    dispatch(actions.infosCallSuccess(response.data))

                    if (onSuccess)
                        dispatch({
                            type: onSuccess,
                            payload: { type, code, data: response.data },
                        })
                })
                .catch((error) => {
                    dispatch(actions.infosCallFailed(error.message))
                    if (onError)
                        dispatch({ type: onError, payload: { type, code } })
                })
        }

        if (type === TYPE_ACCOUNT) {
            await getAccountId(code)
                .then((response) => {
                    console.log(response.data)
                    dispatch(actions.infosCallSuccess(response.data))

                    if (onSuccess)
                        dispatch({
                            type: onSuccess,
                            payload: { type, code, data: response.data },
                        })
                })
                .catch((error) => {
                    dispatch(actions.infosCallFailed(error.message))
                    if (onError)
                        dispatch({ type: onError, payload: { type, code } })
                })
        }

        if (type === TYPE_ACCOUNT_BY_NUMBER) {
            await getAccountByAccountNumber(code)
                .then((response) => {
                    dispatch(actions.infosCallSuccess(response.data[0]))

                    if (onSuccess)
                        dispatch({
                            type: onSuccess,
                            payload: { type, code, data: response.data[0] },
                        })
                })
                .catch((error) => {
                    dispatch(actions.infosCallFailed(error.message))
                    if (onError)
                        dispatch({ type: onError, payload: { type, code } })
                })
        }

        if (type === TYPE_SAVING_CARD) {
            await getCardById(code)
                .then((response) => {
                    dispatch(actions.infosCallSuccess(response.data))

                    if (onSuccess)
                        dispatch({
                            type: onSuccess,
                            payload: { type, code, data: response.data },
                        })
                })
                .catch((error) => {
                    dispatch(actions.infosCallFailed(error.message))
                    if (onError)
                        dispatch({ type: onError, payload: { type, code } })
                })
        }
    }

export default infos
