import { THEME_ENUM } from 'constants/theme.constant'
import { getEnvVars } from 'utils/FeaturesValidator'

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

export const themeConfig = {
    themeColor: 'blue',
    direction: THEME_ENUM.DIR_LTR,
    mode: THEME_ENUM.MODE_DARK,
    primaryColorLevel: 600,
    cardBordered: true,
    panelExpand: false,
    controlSize: 'md',
    navMode: THEME_ENUM.NAV_MODE_DARK,
    layout: {
        type:
            getEnvVars('menu_layout') === '1'
                ? THEME_ENUM.LAYOUT_TYPE_DECKED
                : THEME_ENUM.LAYOUT_TYPE_STACKED_SIDE,
        sideNavCollapse: false,
    },
}
