import BaseService from './BaseService'

export const getPartitions = () => {
    const url = `/partitions`

    return BaseService.get(url)
}

export const getPartitionByName = (name) => {
    const url = `/partitions?name=${name}`

    return BaseService.get(url)
}

export const getPartition = (id) => {
    const url = '/partitions/' + id

    return BaseService.get(url)
}