import BaseService from './BaseService'

export const getProducts = () => {
    const url = '/account_profiles'
    return BaseService.get(url)
}

export const getProductsByCode = (code) => {
    const url = `/account_profiles?code=${code}`
    return BaseService.get(url)
}

export const createCompte = (data) => {
    const url = '/accounts'
    let data_ = {}

    data.customerId === ''
        ? (data_ = {
            name: data.name,
            profile: data.profile,
            currency: data.currency,
        })
        : (data_ = data)

    return BaseService.post(url, data_)
}

export const getSearchComptes = (url_) => {
    const url = `/accounts?itemsPerPage=100000${url_ !== '' ? '&' : ''
        }${url_}&order[accountNumber]=desc`
    return BaseService.get(url)
}

export const getJournal = (accountId, startDate, endDate) => {
    const url = `/entries?itemsPerPage=100000&accountId=${accountId}&date[after]=${startDate}&date[before]=${endDate}`

    return BaseService.get(url)
}

export const getAccounts = () => {
    const url = `/accounts?itemsPerPage=100000`

    return BaseService.get(url)
}

export const getAccountByAccountNumber = (accountNumber) => {
    const url = `/accounts?accountNumber=${accountNumber}`

    return BaseService.get(url)
}

export const getAccountByCustomer = (id) => {
    const url = `/accounts?holderId=${id}`

    return BaseService.get(url)
}

export const updateCompte = (name, id) => {
    const url = '/accounts/' + id
    let data = { name }

    return BaseService.put(url, data)
}

export const getAccountPartition = () => {
    const url = `/accounts?itemsPerPage=100000`
    // const url = `/accounts`

    return BaseService.get(url)
}

export const getAccountByAccountNumberAndPartition = (
    accountNumber,
    partition
) => {
    const url = `/accounts?accountNumber=${accountNumber}&branchId=${partition}`

    return BaseService.get(url)
}

export const getAccountId = (id) => {
    const url = `/accounts/${id}`

    return BaseService.get(url)
}

export const getReceipt = (id) => {
    const url = `/operations/cashin_cashouts/${id}`

    return BaseService.get(url)
}

export const resetPassword = (data, id) => {
    const url = `/users/${id}/credentials`

    return BaseService.put(url, data)
}
